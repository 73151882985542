html,
body {
  height: 100%;
  scroll-behavior: smooth;
}

#__next {
  min-height: 100%;
  display: flex;
  flex-direction: column;
}

.content {
  display: flex;
  flex: 1 0 auto;
}

.footer {
  flex-shrink: 0;
}
